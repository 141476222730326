import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useContext, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ATTACHMENT_STATUS } from '@pages/announcement-management/view/models';
import { STATUS_NAME } from '@pages/project-management/detail/constant';
import { renderTaskStatus } from '@pages/project-management/detail/utils/helper';
import { DEADLINE_STATUS } from '@pages/project-management/task/constants';
import {
  IDetailTaskData,
  IFormMOT,
  IFormStakeHolder,
  IOptions,
  IStatus,
  ProjectTaskPic,
  StatusCategory
} from '@pages/project-management/task/models';
import { TaskDetailContext } from '@pages/project-management/task/view';

import { BasePopupProps, showBasePopup } from '@components/base-popup/BasePopup';
import { FormInputDate } from '@components/form-input-date/FormInputDate';
import FormMultipleSelect, { IConfirmCallbackParams } from '@components/form-select/FormMultipleSelect';
import { FormSelect } from '@components/form-select/FormSelect';
import SelectionPreview from '@components/form-select/SelectionPreview';

import useFetch from '@hooks/useFetch';
import useMutation from '@hooks/useMutation';

import { CATEGORY } from '@utils/constants';
import { API } from '@utils/constants/Apis';
import { FORMAT_DATE_EN } from '@utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@utils/constants/Http';

type Props = {
  detailData: IDetailTaskData;
  isEdit?: boolean;
  fetchData: () => Promise<void>;
};

type TASK_TYPE_SHOW_PIC = typeof CATEGORY.CS | typeof CATEGORY.SUPPORT | typeof CATEGORY.MOT;

export const TaskDetailQuickUpdateForm = (props: Props) => {
  const { detailData, isEdit, fetchData } = props;
  const { t } = useTranslation();
  const { idProject, idTask } = useParams();

  const { setIsWarningDeadline } = useContext(TaskDetailContext);

  const { mutate: updateDeadline } = useMutation(API.UPDATE_DEADLINE_TASK_DETAIL(idProject ?? '', idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const queryStakeHolder = useRef({ query: 'activeOnly' });
  const stakeHolderEndpoint = detailData && detailData.taskType === CATEGORY.OTHER ? API.GET_STAKEHOLDERS_BY_PROJECT(idProject ?? '') : '';
  const { data: stakeHolderEmails } = useFetch<IFormStakeHolder[]>(stakeHolderEndpoint, 'GET', queryStakeHolder.current);
  const userPicEndpoint =
    detailData && [CATEGORY.CS, CATEGORY.SUPPORT, CATEGORY.MOT].includes(detailData.taskType as TASK_TYPE_SHOW_PIC)
      ? API.GET_USERS_MOT_PIC(true)
      : '';
  const { data: userPics } = useFetch<IFormMOT[]>(userPicEndpoint, 'GET');
  const { mutate: updatePic } = useMutation(API.UPDATE_PIC_TASK_DETAIL(idProject ?? '', idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? '',
    defaultErrorMsg: 'MSG_C_019',
    paramMsg: {
      field: t('project:task_of_project:mot_pick:label')
    }
  });
  const { data: listStatus } = useFetch<IStatus[]>('/prj/status?type=task', 'GET');
  const { mutate: updateStatus } = useMutation(API.UPDATE_STATUS_TASK_DETAIL(idProject ?? '', idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const { watch, setValue, reset, resetField } = useFormContext();

  const isHaveFileWaiting = useMemo(() => {
    return !!detailData?.projectTaskAttachmentFiles?.find((item) => item.status === ATTACHMENT_STATUS.WAITING && !item.deleted);
  }, [detailData]);
  const isTaskDelay = detailData?.statusName !== STATUS_NAME.COMPLETED && dayjs(detailData?.deadline).isBefore(dayjs(), 'day');
  const isSupportTask = detailData?.taskType === CATEGORY.SUPPORT;

  const picOptions = (): {
    [key: string]: IOptions[];
  } => {
    const results: {
      [key: string]: IOptions[];
    } = {
      all: [],
      filtered: []
    };
    if (!detailData) return results;
    if (detailData.taskType === CATEGORY.OTHER) {
      results.all =
        stakeHolderEmails?.map((f: IFormStakeHolder) => ({
          id: f.stakeHolderId,
          label: f.stakeHolderName,
          value: f.stakeHolderEmail
        })) ?? [];
      results.filtered = results.all;
    } else if ([CATEGORY.CS, CATEGORY.SUPPORT, CATEGORY.MOT].includes(detailData.taskType as TASK_TYPE_SHOW_PIC)) {
      userPics?.forEach((f: IFormMOT) => {
        results.all.push({
          id: f.id ?? '',
          label: f.name,
          value: f.email
        });
        if (f.status)
          results.filtered.push({
            id: f.id ?? '',
            label: f.name,
            value: f.email
          });
      });
    }
    return results;
  };
  const statusOptions = listStatus?.reduce((options: DefaultOptionType[], item: IStatus) => {
    if (!detailData) {
      return [];
    }
    if (isSupportTask) {
      options.push({
        label: item.name,
        value: item.id
      });
    } else if (!item.types?.includes(StatusCategory.TASK_SUPPORT)) {
      options.push({
        label: item.name,
        value: item.id
      });
    }
    return options;
  }, []);

  const handleChangeDeadline = async (value: Dayjs | null) => {
    try {
      const result = await updateDeadline({ deadline: value ? dayjs(value).format(FORMAT_DATE_EN) : null });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) fetchData();
    } catch (error) {}
  };

  const handleOnChangePic = (selectedValues: (ProjectTaskPic & IOptions)[]) => {
    const items = selectedValues.map((item: any) => {
      return {
        label: item.label,
        value: item.value
      };
    });
    setValue('picId', items);
  };

  const handlePicCondition = async () => {
    const content: BasePopupProps = {
      type: 'confirm',
      title: t('project:task_of_project:change_pic:title') || '',
      msg: t('project:task_of_project:change_pic:content')
    };
    return showBasePopup(content);
  };

  const handleConfirmPic = async ({ removed, added, original }: IConfirmCallbackParams<ProjectTaskPic & IOptions>) => {
    try {
      const picPayloads: Partial<ProjectTaskPic & IOptions>[] = [];
      removed.forEach((item) => {
        const matched = original.find((i) => i.value === item.value);
        if (!matched) return;
        picPayloads.push({ ...matched, picEmail: item.value, deleted: true });
      });
      added.forEach((item) => {
        picPayloads.push({ picEmail: item.value });
      });
      const result = await updatePic({ pics: picPayloads });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        await fetchData();
      } else {
        reset((prev) => ({
          ...prev,
          picId: original
        }));
      }
    } catch (error) {}
  };

  const handleCancelPic = () => {
    const originValues =
      detailData.projectTaskPics.map((item) => ({
        ...item,
        label: item.picName,
        value: item.picEmail
      })) ?? [];
    reset((prev) => ({
      ...prev,
      picId: originValues
    }));
  };

  const handleChangeStatus = async (value: string) => {
    try {
      const result = await updateStatus({ statusId: value });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) fetchData();
      else resetField('status', { defaultValue: detailData?.statusName });
    } catch (errors) {}
  };

  const handlePreChangeStatus = async (newVal: string) => {
    let content: BasePopupProps = {};
    if (!detailData?.deadline) setIsWarningDeadline?.(true);
    else {
      content = {
        type: 'confirm',
        title: t('project:task_of_project:change_status:title') || '',
        msg:
          detailData.projectTaskPics && detailData.projectTaskPics.length
            ? t('common:MSG_C_021', { item: t('project:task_of_project:status:label') || '' })
            : t('common:MSG_P_031')
      };
      const response = await showBasePopup(content);
      if (response === 'confirm') {
        handleChangeStatus(newVal);
      } else throw new Error('cancel');
    }
  };

  const deadline = watch('status');

  const messageWarning = useMemo(() => {
    if ((!detailData?.deadline && detailData?.statusName === STATUS_NAME.TODO) || !detailData?.departureDate) return '';
    if (!deadline && detailData?.statusName !== STATUS_NAME.TODO) return DEADLINE_STATUS.ERROR;
    if (!!detailData?.deadline) {
      const isWarning = dayjs(detailData?.deadline).isAfter(dayjs(detailData?.departureDate));
      if (!isWarning) return;
      return DEADLINE_STATUS.WARNING;
    }
  }, [detailData, deadline]);

  return (
    <div className='flex gap-[16px] justify-end'>
      <div className='flex gap-[8px]'>
        <div className='text-lnk text-[14px] font-medium flex mt-[12px]'>{t('project:task_of_project:deadline')}</div>
        <div className={isSupportTask && isEdit ? 'w-[252px]' : 'w-[295px]'}>
          <FormInputDate
            name='deadline'
            labelTx=''
            placeholder={t('placeholder:date')}
            triggerShowModal='both'
            allowClear={false}
            customMessage={() => {
              if (!messageWarning) return { status: '', content: '' };
              if (messageWarning === DEADLINE_STATUS.ERROR) {
                return {
                  status: DEADLINE_STATUS.ERROR,
                  content: (
                    <div className='text-[14px] font-normal leading-[22px] h-[26px] mt-0 text-negative'>
                      {t('common:MSG_001_textbox', { field: t('project:task_of_project:deadline') })}
                    </div>
                  )
                };
              }
              return {
                status: DEADLINE_STATUS.WARNING,
                content: (
                  <div className='text-[14px] font-normal leading-[22px] mt-0 text-warning'>
                    {t('project:task_of_project:warning_deadline_after_departure_date')}
                  </div>
                )
              };
            }}
            onChange={handleChangeDeadline}
          />
        </div>
      </div>
      <div className='flex gap-[8px] items-baseline'>
        <div className='text-lnk text-[14px] font-medium flex items-center'>{t('project:task_of_project:mot_pick:label')}</div>
        <div className='w-[314px]'>
          <FormMultipleSelect<ProjectTaskPic & IOptions>
            name='picId'
            mode='multiple'
            label=''
            options={picOptions().filtered}
            onChange={handleOnChangePic}
            confirmations={{
              condition: handlePicCondition,
              onCancel: handleCancelPic,
              onConfirm: handleConfirmPic
            }}
            tagShowTooltip
            labelInValue
            disabled={detailData?.taskType === CATEGORY.MOT}
            placeholder={detailData?.taskType === CATEGORY.MOT ? '' : t('project:task_of_project:mot_pick:placeholder')}
          />
        </div>
      </div>
      <div className='flex gap-[8px] items-baseline'>
        <div className='text-lnk text-[14px] font-medium flex items-center'>{t('project:task_of_project:status:label')}</div>
        <div className={classNames('shrink-0', isSupportTask ? 'w-[151px]' : 'w-[101px]')}>
          <SelectionPreview options={statusOptions as DefaultOptionType[]} value={watch('status')} label={detailData?.statusName || ''}>
            <FormSelect
              allowClear={false}
              name='status'
              label=''
              options={statusOptions}
              disabled={isHaveFileWaiting}
              placeholder={t('project:task_of_project:status:placeholder')}
              preChange={handlePreChangeStatus}
            />
          </SelectionPreview>
        </div>
      </div>
      {isTaskDelay && (
        <div className='h-[40px] flex items-center'>{renderTaskStatus(STATUS_NAME.DELAYED, '!w-auto px-[8px] py-[2px] !text-base')}</div>
      )}
    </div>
  );
};
