import { useAppDispatch } from '@/hooks';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space, Spin, Table, Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ATTACHMENT_STATUS, AttachmentFile } from '@/pages/announcement-management/view/models';
import { STATUS_NAME } from '@pages/project-management/detail/constant';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization from '@/hooks/useAuthorization';

import { ERROR_DETAIL, NOTE_FILED_MAX_LENGTH, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { MAXIMUM_FILE_UPLOAD, MAXIMUM_SIZE_UPLOAD_TO_BYTE } from '@/utils/constants/announcement';
import { DataViewer } from '@/utils/helpers/common';
import { ALLOW_EXTENSION, splitAccept, splitFileName } from '@/utils/helpers/fileHelper';
import { uploadMultipleFileWithType } from '@/utils/services/FileService';

import AppovedIcon from '@/assets/icons/Check.svg';
import RejectIcon from '@/assets/icons/Reject.svg';
import TrashBlack from '@/assets/icons/TrashBlack.svg?react';
import UploadImage from '@/assets/icons/UploadCompleted.svg?react';
import UploadSimpleActive from '@/assets/icons/UploadSimpleActive.svg';

import { EditorWithCounter } from '../form-box-editor';
import { renderFileItem } from './AttachmentFile';

import './Styles.scss';

const { Dragger } = Upload;

export interface IFormAttachment {
  onFilesUploaded?: (files: UploadFile[]) => void;
  onHandleRemoveFile?: (file: any, index: number) => void;
  onChange?: (file: any) => void;
  accept?: string;
  multiple?: boolean;
  maxFileSize?: number;
  maxFiles?: number;
  documentType?: string;
  name: string;
  isEdit?: boolean;
  allowRemove?: boolean;
  allowApproveReject?: boolean;
  requestApproval?: boolean;
  wrapperClassName?: string;
  optionsData?: {
    status?: string;
  };
  showTimeUpload?: boolean;
  statusName?: string;
}

interface IResponse {
  files: any[];
  filesError: any[];
}

export const FormAttachmentTable = (props: IFormAttachment) => {
  const {
    onFilesUploaded,
    onHandleRemoveFile,
    onChange,
    name,
    accept,
    multiple,
    maxFileSize,
    maxFiles,
    documentType,
    wrapperClassName,
    allowRemove,
    allowApproveReject,
    requestApproval,
    isEdit = false,
    optionsData,
    showTimeUpload = false,
    statusName
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { control } = useFormContext();
  const { user } = useAuthorization();

  const {
    fields: uploadedFiles,
    append,
    update,
    remove
  } = useFieldArray({
    name,
    control,
    keyName: 'key',
    rules: {
      maxLength: MAXIMUM_FILE_UPLOAD
    }
  });

  const dispatch = useAppDispatch();
  const expandedRowKeys = useMemo(() => {
    return uploadedFiles.filter((item: any) => item.status === ATTACHMENT_STATUS.REJECT).map((e: any) => e.id);
  }, [uploadedFiles]);

  const disabledApproveRequest = (file: AttachmentFile) => {
    if (!isEdit || statusName !== STATUS_NAME.TODO || file.createdBy !== user?.id) {
      return !!file.createdDate;
    }
    return false;
  };

  const validateFile = (file: any) => {
    const validFileTypes = splitAccept(accept);
    const fileExtension = splitFileName(file.name);
    if (!!accept && (!fileExtension || !validFileTypes.includes(`.${fileExtension}`))) {
      return {
        id: file.uid,
        name: file.name,
        status: UPLOAD_STATUS.ERROR,
        msgErr: t('common:MSG_C_008', { files: accept })
      };
    }
    if (maxFileSize && file.size > maxFileSize) {
      return {
        id: file.uid,
        name: file.name,
        status: UPLOAD_STATUS.ERROR,
        msgErr: t('common:MSG_C_006')
      };
    }
    const isDuplicateName = uploadedFiles.some((item: any) => item.name === file.name);
    if (isDuplicateName) {
      return {
        id: file.uid,
        name: file.name,
        status: UPLOAD_STATUS.ERROR,
        msgErr: t('common:MSG_C_009', { fileName: file.name })
      };
    }
    return file;
  };

  const getErrorMessage = (error: string) => {
    const ErrorMapping = {
      [ERROR_DETAIL.FILE_NAME_OVER_SIZE]: t('common:MSG_C_030'),
      [ERROR_DETAIL.DOCUMENT_TYPE_NOT_SUPPORT]: t('common:MSG_C_008', { files: accept }),
      [ERROR_DETAIL.FILE_EXTENSION_IGNORE]: t('common:MSG_C_008', { files: accept })
    };

    return ErrorMapping[error] ?? t('common:MSG_FILE_INVALID');
  };

  const onUploadFile = async (files: any): Promise<IResponse> => {
    const formData = new FormData();
    files.forEach((item: any) => {
      const file = new File([item], item.name);
      formData.append(`files`, file);
    });

    return new Promise((resolve: any, reject: any) => {
      uploadMultipleFileWithType(documentType ?? '', formData)
        .then((res) => {
          const { data } = res;
          let filesNew = [];
          let filesError = [];
          if (data.files?.length) {
            filesNew = data.files.map((file: any) => ({
              ...file,
              requestApproval: !!requestApproval,
              status: requestApproval ? ATTACHMENT_STATUS.WAITING : ATTACHMENT_STATUS.DONE
            }));
          }
          if (data.errors?.length) {
            filesError = data.errors.map((file: any) => ({
              id: uuidv4(),
              name: file.name,
              msgErr: getErrorMessage(file.errorDetail),
              status: UPLOAD_STATUS.ERROR
            }));
          }
          resolve({
            files: filesNew,
            filesError
          });
        })
        .catch((error) => reject(new Error(error)));
    });
  };
  const handleRemoveFile = (file: any, index: number) => {
    const newFileList = uploadedFiles.filter((item: any) => item.id !== file.id);
    remove(index);
    onChange?.(newFileList);
    onHandleRemoveFile?.(file, index);
  };

  const beforeUpload = debounce(async (_, fileList: any) => {
    setLoading(true);
    if (maxFiles && fileList.length + uploadedFiles?.length > maxFiles) {
      dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          message: t('common:MSG_C_007')
        })
      );
      setLoading(false);
      return false;
    }
    const { fileValid, fileError } = fileList.reduce(
      (acc: any, file: any) => {
        const validation = validateFile(file);
        if (validation?.status === UPLOAD_STATUS.ERROR) {
          acc.fileError.push(validation);
        } else {
          acc.fileValid.push(validation);
        }
        return acc;
      },
      { fileValid: [], fileError: [] }
    );
    let updatedFiles = fileError;
    if (fileValid.length) {
      const res = await onUploadFile(fileValid);
      updatedFiles = [...updatedFiles, ...res.files, ...res.filesError];
    }

    append(updatedFiles);
    updatedFiles = [...uploadedFiles, ...updatedFiles];
    onFilesUploaded?.(updatedFiles);
    onChange?.(updatedFiles);
    setLoading(false);
    return true;
  }, 500);

  const columns = [
    {
      title: 'No',
      width: 60,
      className: 'w-[60px]',
      render: (_: any, record: any, index: number) => {
        ++index;
        return index;
      }
    },
    {
      title: t('announcement:add:table:file_name'),
      dataIndex: 'name',
      key: 'name',
      width: 500,
      className: 'w-[500px] file-name',
      render(_: any, record: any, index: number) {
        return renderFileItem({
          t,
          attachment: record as AttachmentFile,
          isEdit,
          classContainer: 'px-[8px]',
          extraAction: allowRemove && (
            <Button
              type='text'
              icon={<img src={TrashBlack} className='h-[20px] w-[20px] hover:cursor-pointer' alt='upload-simple' />}
              onClick={() => {
                handleRemoveFile(record, index);
              }}
            />
          ),
          rejectElement: <></>
        });
      }
    },
    {
      title: t('announcement:add:table:approval_required'),
      dataIndex: 'deleted',
      key: 'deleted',
      width: 80,
      className: 'w-[80px]',
      render(_: any, record: any, index: number) {
        if (record.status === UPLOAD_STATUS.ERROR) return null;
        return (
          <div className='text-center'>
            <Checkbox
              disabled={disabledApproveRequest(record)}
              checked={!!record.requestApproval}
              className='border-b-0'
              onChange={(e) =>
                update(index, {
                  ...record,
                  requestApproval: e.target.checked,
                  status: e.target.checked ? ATTACHMENT_STATUS.WAITING : UPLOAD_STATUS.DONE
                })
              }
            />
          </div>
        );
      }
    },
    ...(showTimeUpload
      ? [
          {
            title: t('announcement:add:table:uploader'),
            width: 140,
            className: 'w-[140px]',
            ellipsis: true,
            render(_: any, record: any) {
              return (
                <AppTooltip title={DataViewer.display(record?.createdName)}>
                  <div className='truncate w-[124px]'>{DataViewer.display(record?.createdName)}</div>
                </AppTooltip>
              );
            }
          },
          {
            title: t('announcement:add:table:upload_date_time'),
            width: 140,
            className: 'w-[140px]',
            render(_: any, record: any) {
              return DataViewer.displayTime(record?.createdDate);
            }
          }
        ]
      : []),
    ...(allowApproveReject && isEdit && optionsData?.status === ATTACHMENT_STATUS.WAITING
      ? [
          {
            title: t('announcement:add:table:action'),
            width: 140,
            className: 'w-[140px]',
            ellipsis: true,
            render(_: any, attachment: any, index: number) {
              const { requestApproval, status, createdName, createdBy } = attachment;
              if (!requestApproval || status !== ATTACHMENT_STATUS.WAITING || !createdName) return null;
              if (createdBy === user?.id) return null;

              return (
                <Space>
                  <AppTooltip title={t('announcement:approved')}>
                    <AppovedIcon
                      className='cursor-pointer hover:opacity-70'
                      onClick={() =>
                        update(index, {
                          ...attachment,
                          status: ATTACHMENT_STATUS.APPROVAL
                        })
                      }
                    />
                  </AppTooltip>
                  <AppTooltip title={t('announcement:reject')}>
                    <RejectIcon
                      className='cursor-pointer hover:opacity-70'
                      onClick={() =>
                        update(index, {
                          ...attachment,
                          status: ATTACHMENT_STATUS.REJECT
                        })
                      }
                    />
                  </AppTooltip>
                </Space>
              );
            }
          }
        ]
      : [])
  ];

  return (
    <div className={classNames('form-upload-file', wrapperClassName)}>
      {
        <Dragger
          name='file'
          height={174}
          multiple={multiple}
          beforeUpload={beforeUpload}
          showUploadList={false}
          accept={accept}
          openFileDialogOnClick={false}
          disabled={loading}
        >
          <Space direction='horizontal'>
            <img src={UploadImage} alt='upload' className='h-[150px] pointer-events-none' />
            <div className='flex flex-col items-start'>
              <div className='text-[18px] font-semibold'>{t('basic_information:attachment_file_title')}</div>
              <div className='text-textGray mt-4'>{t('basic_information:attachment_file_description')}</div>
              <Upload name='file' multiple={multiple} beforeUpload={beforeUpload} showUploadList={false} accept={accept} disabled={loading}>
                <BaseButton type='secondary' disabled={loading} className='flex items-center'>
                  {loading ? (
                    <Spin
                      indicator={
                        <Loading3QuartersOutlined className='[&>svg]:w-[18px] [&>svg]:h-[18px] !leading-none' style={{ fontSize: 18 }} spin />
                      }
                    />
                  ) : (
                    <UploadSimpleActive className='w-[18px] h-[18px] [&>path]:duration-200 [&>path]:ease-[cubic-bezier(0.6450.0450.3551)]' />
                  )}
                  {t('basic_information:browser_file')}
                </BaseButton>
              </Upload>
            </div>
          </Space>
        </Dragger>
      }
      {uploadedFiles?.length > 0 && (
        <div className='files-upload mt-[16px]'>
          <Table
            className='mb-2'
            dataSource={uploadedFiles.map((item: any) => ({ ...item, key: item.id }))}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
            expandable={{
              expandedRowRender: (record: any, index) =>
                record.requestApproval && isEdit && !record?.rejectReason ? (
                  <div className='ml-[60px] w-[500px] py-[12px]'>
                    <EditorWithCounter
                      required={true}
                      defaultValue={record?.rejectReason}
                      name={`${name}.[${index}].rejectReason`}
                      toolbar={null}
                      limit={NOTE_FILED_MAX_LENGTH}
                      styleCounterLabel={'text-gray-3'}
                      label={t('announcement:reason_remand:label') ?? ''}
                      placeholder={t('announcement:reason_remand:placeholder') ?? ''}
                      editorWrapperProps={{ className: '!h-[126px]' }}
                    />
                  </div>
                ) : (
                  <div className=' bg-gray1 p-[12px] flex align-top gap-2 ml-[60px] w-[500px] '>
                    <div className='whitespace-nowrap'>{t('announcement:reason')} :</div>
                    <div
                      className='flex-1 reject-reason'
                      dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.rejectReason) }}
                    />
                  </div>
                ),
              rowExpandable: (record) => record.status === ATTACHMENT_STATUS.REJECT,
              defaultExpandAllRows: true,
              showExpandColumn: true,
              expandedRowKeys
            }}
          />
          <Upload
            name='file'
            multiple={multiple}
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept={accept}
            disabled={(maxFiles !== undefined && uploadedFiles.length >= maxFiles) || loading}
          >
            <BaseButton
              type='secondary'
              className='flex items-center'
              disabled={(maxFiles !== undefined && uploadedFiles.length >= maxFiles) || loading}
            >
              {loading ? (
                <Spin
                  indicator={<Loading3QuartersOutlined className='[&>svg]:w-[18px] [&>svg]:h-[18px] !leading-none' style={{ fontSize: 18 }} spin />}
                />
              ) : (
                <UploadSimpleActive className='w-[18px] h-[18px] [&>path]:duration-200 [&>path]:ease-[cubic-bezier(0.6450.0450.3551)]' />
              )}
              {t('basic_information:browser_file')}
            </BaseButton>
          </Upload>
        </div>
      )}
    </div>
  );
};
FormAttachmentTable.defaultProps = {
  accept: ALLOW_EXTENSION,
  multiple: true,
  maxFileSize: MAXIMUM_SIZE_UPLOAD_TO_BYTE,
  maxFiles: MAXIMUM_FILE_UPLOAD,
  documentType: 'attachments',
  allowRemove: false,
  allowApproveReject: false
};
