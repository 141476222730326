import classNames from 'classnames';
import { ReactNode } from 'react';

import { STATUS_NAME, StatusName } from '@/pages/project-management/detail/constant';

import { ExclamationTriangleFillIcon, PaperPlaneTopIcon } from '@components/icon-svg';

import { formatDateTime, getMaxByKey, getMinByKey, groupByKey } from '@/utils/helpers/globalHelper';
import { CATEGORY } from '@utils/constants';

import CompletedIcon from '@/assets/icons/CompletedIcon.svg';
import InprogressIcon from '@/assets/icons/InprogressIcon.svg';
import TodoIcon from '@/assets/icons/TodoIcon.svg';
import PendingIcon from '@/assets/icons/WarningWaitingApprove.svg';

import { ITask } from '.';

const TagTask = (props: { icon: ReactNode; status: string; className?: string }) => {
  const { icon, status, className } = props;

  return (
    <div
      className={classNames(
        'w-[80px] flex justify-center items-center gap-[4px] text-[12px] font-medium border-[1px] border-solid rounded-[4px]',
        className
      )}
    >
      {icon && <div className='shrink-0 flex'>{icon}</div>}
      {status}
    </div>
  );
};

export const renderTaskStatus = (statusName?: StatusName, className?: string, hiddenIcon?: boolean) => {
  if (!statusName) {
    return null;
  }
  const configs = new Map<string, { icon: ReactNode; className: string }>([
    [
      STATUS_NAME.TODO,
      {
        icon: <TodoIcon />,
        className: 'text-gray3 border-gray3 bg-gray1'
      }
    ],
    [
      STATUS_NAME.DELAYED,
      {
        icon: <ExclamationTriangleFillIcon className='text-negative' />,
        className: 'text-negative border-negative bg-[#FFF1F0]'
      }
    ],
    [
      STATUS_NAME.IN_PROGRESS,
      {
        icon: <InprogressIcon />,
        className: 'text-link border-link bg-blue3'
      }
    ],
    [
      STATUS_NAME.COMPLETED,
      {
        icon: <CompletedIcon />,
        className: 'text-positive border-positive bg-green10'
      }
    ],
    [
      STATUS_NAME.PENDING_VERIFICATION,
      {
        icon: <PendingIcon />,
        className: 'text-warning border-warning bg-yellow10'
      }
    ],
    [
      STATUS_NAME.SUBMITTED,
      {
        icon: <CompletedIcon />,
        className: 'text-positive border-positive bg-green10'
      }
    ],
    [
      STATUS_NAME.NOT_SUBMITTED,
      {
        icon: <TodoIcon />,
        className: 'text-gray3 border-gray3 bg-gray1'
      }
    ],
    [
      STATUS_NAME.DOUBLE_CHECK,
      {
        icon: <PaperPlaneTopIcon className='text-purple' />,
        className: '!w-auto max-w-[122px] px-[8px] text-purple border-purple bg-[#FAFAFE]'
      }
    ],
    [
      STATUS_NAME.PENDING,
      {
        icon: <PaperPlaneTopIcon className='text-warning' />,
        className: 'text-warning border-warning bg-yellow10'
      }
    ]
  ]);

  const statusConfig = configs.get(statusName) ?? { icon: null, className: '' };

  return <TagTask icon={hiddenIcon ? null : statusConfig.icon} status={statusName} className={classNames(statusConfig.className, className)} />;
};

export const renderDataTask = (listTask: ITask[]) => {
  const groups = groupByKey(listTask, 'taskType');

  const listRenderDate = listTask.filter((i) => [CATEGORY.MOT.toString(), CATEGORY.OTHER.toString()].includes(i.taskType));
  const isAllTaskCompleted = listRenderDate.every(isComplete);

  const maxDeadlineDate = getMaxByKey(listRenderDate, 'deadline')?.deadline;
  const minStartDate = getMinByKey(listRenderDate, 'inprogressDate')?.inprogressDate;
  const maxCompletedDate = isAllTaskCompleted ? getMaxByKey(listRenderDate, 'completeDate')?.completeDate : '';

  return {
    groups,
    maxDeadlineDate: formatDateTime(maxDeadlineDate ?? ''),
    minStartDate: formatDateTime(minStartDate ?? ''),
    maxCompletedDate: formatDateTime(maxCompletedDate ?? ''),
    status: getStatusTask(listTask)
  };
};

export const isTodo = (i: ITask) => i.statusName === STATUS_NAME.TODO;
export const isComplete = (i: ITask) => i.statusName === STATUS_NAME.COMPLETED;
export const isPending = (i: ITask) => i.statusName === STATUS_NAME.DELAYED;

export const getStatusTask = (listTask: ITask[], isCheckAllTaskType = false) => {
  const listCheckStatus = isCheckAllTaskType
    ? listTask
    : listTask.filter((i) => [CATEGORY.MOT.toString(), CATEGORY.OTHER.toString()].includes(i.taskType));

  const isDelay = listCheckStatus.length && listCheckStatus.some(isPending);
  if (isDelay) {
    return STATUS_NAME.DELAYED;
  }

  const isNotStart = listCheckStatus.length && listCheckStatus.every(isTodo);
  if (isNotStart) {
    return STATUS_NAME.TODO;
  }

  const isCompleted = listCheckStatus.length && listCheckStatus.every(isComplete);
  if (isCompleted) {
    return STATUS_NAME.COMPLETED;
  }

  const inProgress = listCheckStatus.length && !isNotStart && !isCompleted && !isDelay;
  if (inProgress) {
    return STATUS_NAME.IN_PROGRESS;
  }

  return '';
};
