import * as yup from 'yup';

import { QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { getTemplates } from '@/utils/services/template';
import { MAX_SECTIONS, TASK_MAX_LENGTH, TEXT_FIELD_SHORT_MAX_LENGTH } from '@utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@utils/method';

import { TemplateListQueryFactory } from '../list/ultis';

const validateDuplicate = async (title: string, ctx: any): Promise<boolean> => {
  const countryId = ctx.parent.countryId;
  const id = ctx.parent.id;
  const factory = new TemplateListQueryFactory();
  factory.and({
    fieldTitle: 'countryId',
    queryType: QUERY_TYPE.GUID,
    queryValue: countryId,
    operation: QUERY_OPERATION.EQUAL
  });

  factory.and({
    fieldTitle: 'nameSearch.ToLower()',
    queryType: QUERY_TYPE.TEXT,
    queryValue: convertFullWidthToHalfWidth(title.toLocaleLowerCase()),
    operation: QUERY_OPERATION.EQUAL
  });

  const body = {
    filter: factory.toFilterString()
  };

  const result = await getTemplates(body);
  if (result?.data?.data?.length && result?.data?.data?.some((item: any) => item.id !== id)) {
    return false;
  }

  return true;
};

const TEXT_AREA_DOCUMENT_MAX_LENGTH = 1000;
export const FormTemplate = yup.object().shape({
  countryId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'template_layout:country_name' }
    })
  ),
  templateName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'template:template_name' }
      })
    )
    .max(
      TEXT_FIELD_SHORT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_028',
        optionsTx: { field: 'template:template_name', maxLength: TEXT_FIELD_SHORT_MAX_LENGTH }
      })
    )
    .debounceValidate(
      validateDuplicate,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_019',
        optionsTx: { field: 'template_layout:template_name' || '' }
      })
    ),
  patternId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'template_layout:procedual_process' }
    })
  ),
  documents: yup.array().of(
    yup.object().shape({
      templateProcessTasks: yup.array().of(
        yup.object().shape({
          taskType: yup.string().required(
            stringifyObjectValidateYup({
              keyT: 'common:MSG_001_select',
              optionsTx: { field: 'template_layout:category' }
            })
          ),
          taskId: yup.string().required(
            stringifyObjectValidateYup({
              keyT: 'common:MSG_001_select',
              optionsTx: { field: 'template_layout:title' }
            })
          ),
          detail: yup
            .string()
            .requiredTextEditorChange(
              stringifyObjectValidateYup({
                keyT: 'common:MSG_001_textbox',
                optionsTx: { field: 'project:template_layout:task_content' }
              })
            )
            .htmlStrippedMaxLength(
              TASK_MAX_LENGTH,
              stringifyObjectValidateYup({
                keyT: 'common:MSG_028',
                optionsTx: { field: 'project:template_layout:task_content', maxLength: TASK_MAX_LENGTH }
              })
            )
        })
      )
    })
  ),
  materials: yup.array().of(
    yup.object().shape({
      typeId: yup
        .string()
        .nullable()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_select',
            optionsTx: { field: 'reference_material:reference_material' }
          })
        ),
      numOfCopy: yup
        .string()
        .max(
          MAX_SECTIONS,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_028',
            optionsTx: { field: 'template_layout:number_of_copies', maxLength: MAX_SECTIONS }
          })
        )
        .nullable(''),
      note: yup.string().htmlStrippedMaxLength(
        TEXT_AREA_DOCUMENT_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_028',
          optionsTx: { field: 'template_layout:notes', maxLength: TEXT_AREA_DOCUMENT_MAX_LENGTH }
        })
      ),
      submission: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'template_layout:submission' }
        })
      )
    })
  ),
  tasks: yup.array().of(
    yup.object().shape({
      typeId: yup
        .string()
        .nullable()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_select',
            optionsTx: { field: 'reference_material:reference_material' }
          })
        ),
      numOfCopy: yup
        .string()
        .max(
          MAX_SECTIONS,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_028',
            optionsTx: { field: 'template_layout:number_of_copies', maxLength: MAX_SECTIONS }
          })
        )
        .nullable(''),
      note: yup
        .string()
        .htmlStrippedMaxLength(
          TEXT_AREA_DOCUMENT_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_028',
            optionsTx: { field: 'template_layout:notes', maxLength: TEXT_AREA_DOCUMENT_MAX_LENGTH }
          })
        )
        .nullable('')
    })
  )
});
