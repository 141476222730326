import * as yup from 'yup';

import { QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { TASK_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { stringifyObjectValidateYup } from '@/utils/method';
import { searchPhrase } from '@/utils/services/master';

import { PhrasesQueryFactory } from '../../phrase-list/ultis';

const validateDuplicate = async (title: string, ctx: any): Promise<boolean> => {
  const categoryId = ctx.parent.categoryId;
  const id = ctx.parent.id;
  const factory = new PhrasesQueryFactory();
  factory.and({
    fieldTitle: 'categoryId',
    queryType: QUERY_TYPE.GUID,
    queryValue: categoryId,
    operation: QUERY_OPERATION.EQUAL
  });

  factory.and({
    fieldTitle: 'titleSearch.ToLower()',
    queryType: QUERY_TYPE.TEXT,
    queryValue: convertFullWidthToHalfWidth(title.toLowerCase()),
    operation: QUERY_OPERATION.EQUAL
  });

  const body = {
    filter: factory.toFilterString()
  };

  const result = await searchPhrase(body);
  if (result?.data?.data?.length && result?.data?.data?.some((item: any) => item.id !== id)) {
    return false;
  }

  return true;
};

export const SchemaCreate = yup.object().shape({
  categoryId: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_select',
        optionsTx: { field: 'master_data:phrase:category' || '' }
      })
    )
    .max(
      128,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_028',
        optionsTx: { field: 'master_data:phrase:category' || '', maxLength: 128 }
      })
    ),
  title: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'master_data:phrase:title_label' || '' }
      })
    )
    .max(
      128,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_028',
        optionsTx: { field: 'master_data:phrase:title_label' || '', maxLength: 128 }
      })
    )
    .debounceValidate(
      validateDuplicate,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_019',
        optionsTx: { field: 'master_data:phrase:title_label' || '' }
      })
    ),
  content: yup
    .string()
    .requiredTextEditorChange(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'master_data:phrase:content_label' }
      })
    )
    .nullable()
    .htmlStrippedMaxLength(
      TASK_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_028',
        optionsTx: { field: 'master_data:phrase:content_label', maxLength: TASK_MAX_LENGTH }
      })
    )
});
