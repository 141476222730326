import { Popover } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TemplateListQueryFactory } from '@/pages/template-management/list/ultis';

import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionPreview from '@/components/form-select/SelectionPreview';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { QUERY_CONDITION, QUERY_OPERATION, QUERY_TYPE } from '@/utils/constants';
import { DEFAULT_MAX_ITEM_ALLOW } from '@/utils/constants/AppConstants';
import { QueryItem } from '@/utils/helpers/filters/base';
import { Ii18n } from '@/utils/interfaces/i18n';
import { ITemplate } from '@/utils/interfaces/template';
import { getTemplates } from '@/utils/services/template';

import Slider from '@assets/icons/Sliders.svg?react';

import { DEFAULT_PAGE, FILTER_TEMPLATE } from '../constant';
import { IOptions } from '../utils';
import PrimaryFormFilter from './PrimaryFormFilter';

interface TemplateProps {
  optionsVisaCategory: DefaultOptionType[];
  formOptions: DefaultOptionType[];
  handleChangeTemplate: (value: any) => void;
  countryId: string;
  defaultTemplateName: string;
}

const TemplateFilter = ({ optionsVisaCategory, formOptions, handleChangeTemplate, countryId, defaultTemplateName }: TemplateProps) => {
  const { t }: Ii18n = useTranslation();
  const [filterTemplate, setFilterTemplate] = useState<string>(FILTER_TEMPLATE.NONE);
  const formMethods = useFormContext();
  const [templateOptions, setTemplateOptions] = useState<IOptions[]>([]);
  const { otherCountry } = useOptionsGlobal();

  const primaryValues = formMethods.watch(['primary.category', 'primary.form']);
  const calculateTotalPrimaryFilters = () => {
    let counter = 0;
    primaryValues.forEach((value) => {
      if (!!value) counter++;
    });
    return counter || '';
  };

  const onCLickFilter = () => {
    setFilterTemplate(filterTemplate === FILTER_TEMPLATE.TEMPLATE ? FILTER_TEMPLATE.NONE : FILTER_TEMPLATE.TEMPLATE);
  };
  const generateFilterHeader = () => {
    return (
      <div className='template-detail'>
        <Popover
          open={filterTemplate === FILTER_TEMPLATE.TEMPLATE}
          placement='bottomRight'
          arrow={false}
          overlayInnerStyle={{ padding: '0', transform: 'translateX(17%)', marginTop: '6px' }}
          content={
            <PrimaryFormFilter
              optionsVisaCategory={optionsVisaCategory}
              formOptions={formOptions}
              onSubmit={(values) => {
                formMethods.reset((previous) => ({ ...previous, primary: { ...(values as any) } }));
                formMethods.handleSubmit(updateFilter)();
              }}
              defaultValues={formMethods.getValues('primary')}
            />
          }
          trigger='click'
        >
          <div onClick={() => onCLickFilter()} className='h-full w-full cursor-pointer relative justify-space-between subtitle-14'>
            <span>{t('template_layout:filter')}</span>
            <img src={Slider} className='h-[18px] w-[18px] p-0' alt='sliders' />
            {!!calculateTotalPrimaryFilters() && <span className='number-items'>{calculateTotalPrimaryFilters()}</span>}
          </div>
        </Popover>
      </div>
    );
  };

  const getListTemplate = async (filter: string) => {
    const body = {
      filter,
      pageIndex: DEFAULT_PAGE,
      pageSize: DEFAULT_MAX_ITEM_ALLOW
    };

    const results = await getTemplates(body);
    const templatesRes = results?.data?.data || [];
    if (templatesRes.length) {
      let options: IOptions[] = templatesRes.map((c: ITemplate) => {
        return {
          label: c.name,
          value: c.id
        };
      });
      setTemplateOptions(options);
    } else setTemplateOptions([]);
  };

  const getQueryFilterTemplate = (valueForm?: any) => {
    const factory = new TemplateListQueryFactory();
    const countryFilter = new QueryItem(null, null, undefined, undefined, QUERY_CONDITION.EMPTY, []);
    const countryItem = new QueryItem('countryId', QUERY_TYPE.GUID, countryId, QUERY_OPERATION.EQUAL);
    const otherCountryItem = new QueryItem('countryId', QUERY_TYPE.GUID, otherCountry?.id, QUERY_OPERATION.EQUAL, QUERY_CONDITION.OR);
    countryFilter.childrens?.push(countryItem, otherCountryItem);
    factory.append(countryFilter);

    if (valueForm?.primary?.form)
      factory.and({
        fieldTitle: 'formId',
        queryType: QUERY_TYPE.GUID,
        queryValue: valueForm?.primary?.form,
        operation: QUERY_OPERATION.EQUAL
      });

    if (valueForm?.primary?.category)
      factory.and({
        fieldTitle: 'categoryId',
        queryType: QUERY_TYPE.GUID,
        queryValue: valueForm?.primary?.category,
        operation: QUERY_OPERATION.EQUAL
      });
    return factory.toFilterString();
  };

  const updateFilter = (values: any) => {
    setFilterTemplate(FILTER_TEMPLATE.NONE);
    const queryFilterTemplate = getQueryFilterTemplate(values);
    if (countryId && otherCountry) getListTemplate(queryFilterTemplate);
  };

  useEffect(() => {
    if (!otherCountry || !countryId) return;
    const queryFilterTemplate = getQueryFilterTemplate();
    getListTemplate(queryFilterTemplate);
  }, [countryId, otherCountry]);

  return (
    <div className='select-template-detail flex'>
      <SelectionPreview options={templateOptions} value={formMethods.getValues('templateId')} label={defaultTemplateName}>
        <FormSelect
          name='templateId'
          options={templateOptions}
          includeTextSearch
          allowClear={false}
          className='!w-[280px]'
          placeholder={t('project_detail:template_placeholder')}
          handleChange={handleChangeTemplate}
          onDropdownVisibleChange={(open: boolean) => setFilterTemplate(FILTER_TEMPLATE.FILTER)}
        />
      </SelectionPreview>
      <div className='right-children filter-right-children grow-0'>{generateFilterHeader()}</div>
    </div>
  );
};

export default TemplateFilter;
