import { Button, Row } from 'antd';
import classNames from 'classnames';
import { isNil } from 'lodash';
import React, { Key, forwardRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import BasicFormEditor from '@/components/form-box-editor';
import { FormCheckbox } from '@/components/form-checkbox/FormCheckbox';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionOverrideLabel from '@/components/form-select/SelectionOverrideLabel';
import { PlusIcon } from '@/components/icon-svg/IconSvg';

import useAuthorization, * as s from '@/hooks/useAuthorization';

import { API } from '@/utils/constants/Apis';
import { TEXT_FIELD_MAX_LENGTH, TYPE } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';

import Note from '@/assets/icons/Note.svg';
import TrashBlack from '@/assets/icons/TrashBlack.svg';

import { STATUS_NAME } from '../../detail/constant';
import { DOCUMENT_TYPE, MAX_ITEM, REQUIRED_DOCUMENT_DEFAULT_VALUE, SUBMISSION_MOT } from '../constants';
import { IDocumentList, IDocumentListOption } from '../model';

type Props = {
  formMethod: any;
  type: string;
  documentNameOption: IDocumentListOption[];
  loading: boolean;
  getDocumentName: () => {};
};

const DocumentListComponent = forwardRef((props: Props, ref) => {
  const { formMethod, type, documentNameOption, loading, getDocumentName } = props;
  const { t } = useTranslation();
  const { user, hasPermission, isExternalRole } = useAuthorization();

  const {
    fields: fieldDocumentList,
    append,
    update,
    remove
  } = useFieldArray({
    name: type,
    keyName: 'key',
    shouldUnregister: true,
    control: formMethod.control
  });

  const [projectId, projectCountryId] = formMethod.watch(['projectId', 'projectCountryId']);

  const columnsDefault: FetchColumnsType<any> = [
    {
      title: 'NO',
      key: 'displayOrder',
      dataIndex: 'displayOrder',
      width: '2.8%',
      className: 'h-[38px]',
      render: (value: number) => {
        return <div className='text-center whitespace-nowrap'>{value}</div>;
      }
    },
    {
      title: t('required_documents:document_name'),
      dataIndex: 'documentId',
      render(_value: string, record: IDocumentList, index: number) {
        return (
          <div className='mot-pick relative'>
            <SelectionOverrideLabel label={record?.documentName ?? record?.managementCode ?? ''}>
              <FormSelect
                required
                control={formMethod.control}
                className='w-full'
                options={documentNameOption || []}
                handleChange={(value, op) => handleChangeName(value, op, index)}
                name={`${type}.${index}.documentId`}
                id={`${type}.${index}.documentId`}
                placeholder={String(t('placeholder:select', { field: t('required_documents:document_name') }))}
                maxLengthInputSearch={TEXT_FIELD_MAX_LENGTH}
                optionLabelProp='name'
                allowAddOnSearch
                addRequest={{
                  mappingParams(inputValue) {
                    return {
                      name: inputValue,
                      managementCode: inputValue,
                      projectId,
                      countryId: projectCountryId
                    };
                  },
                  method: 'POST',
                  apiEndPoint: API.GET_DOCUMENT_OPTIONS,
                  defaultParams: {}
                }}
                onAddNew={getDocumentName}
                onSelect={(val: any, op) => {
                  const option = documentNameOption.find((item) => item.value === val) ?? op;
                  if (!option) return;
                  if (val && val === fieldDocumentList[index]?.documentId) {
                    update(index, {
                      ...fieldDocumentList[index],
                      documentName: option?.name ?? option?.label,
                      managementCode: option?.label
                    });
                  }
                }}
              />
            </SelectionOverrideLabel>
          </div>
        );
      },
      width: '21.5%'
    },
    {
      title: t('required_documents:number_of_copies'),
      fixed: 'left',
      dataIndex: 'numberOfCopy',
      width: '8%',
      render(value: string, record: IDocumentList, index: number) {
        return (
          <FormInput
            disabled={!record?.documentName}
            className='min-w-[50px]'
            name={`${type}.${index}.numberOfCopy`}
            placeholder={String(t('placeholder:text_box', { field: t('required_documents:number_of_copies') }))}
            value={value}
          />
        );
      }
    },
    {
      title: t('required_documents:notes'),
      dataIndex: 'note',
      width: '40%',
      render(value: string, record: IDocumentList, index: number) {
        return (
          <BasicFormEditor
            disabled={!record?.documentName}
            className='max-w-64'
            name={`${type}.${index}.note`}
            placeholder={t('placeholder:text_box', { field: t('required_documents:notes') }) ?? ''}
            defaultValue={value}
          />
        );
      }
    }
  ];

  const columnsDocumentList: FetchColumnsType<any> = [
    ...columnsDefault,
    {
      title: t('required_documents:submission_mot'),
      dataIndex: 'submissionToMot',
      render(value: string, record: IDocumentList, index: number) {
        return (
          <div className='mot-pick relative min-w-[92px]'>
            <FormSelect
              disabled={!record?.documentName}
              className='w-full'
              options={submissionMOTOptions}
              name={`${type}.${index}.submissionToMot`}
              id={`${type}.${index}.submissionToMot`}
              placeholder={t('notification:mark_as')}
              value={value}
            ></FormSelect>
          </div>
        );
      },
      width: '10.8%'
    },
    {
      title: t('required_documents:submission_status'),
      dataIndex: 'isConfirm',
      className: 'remove-before',
      render(_value: boolean, record: IDocumentList, index: number) {
        const submissionMOT = record.submissionToMot;
        if (isNil(submissionMOT) || submissionMOT === SUBMISSION_MOT.unnecessary) return DataViewer.display(null);
        const isConfirm = record.isConfirm;
        const text = isConfirm ? t('required_documents:submitted') : t('required_documents:not_submitted');
        const userName = record?.nameConfirm;
        const nameConfirm = record.statusConfirm ? userName : `(${t('account_list:status_account:inactive')}) ${userName}`;
        return (
          <div className={classNames('flex whitespace-pre-wrap', isExternalRole ? 'flex-row' : 'flex-col')}>
            <FormCheckbox name={`${type}.${index}.isConfirm`} handleChange={(value) => handleChangeStatus(value, record)}>
              {text}
            </FormCheckbox>
            {record.isShowSubmittedName && !isNil(record?.statusConfirm) && isConfirm && userName && (
              <p>
                {t('required_documents:confirmed_by')} : {nameConfirm}
              </p>
            )}
          </div>
        );
      },
      width: '10.8%'
    },
    {
      dataIndex: 'process',
      className: '!overflow-visible',
      render(_value: string, record: IDocumentList, index: number) {
        return (
          <Button
            className='!w-[30px] !h-[36px]'
            disabled={couldNotBeDelete(record)}
            type='text'
            icon={<TrashBlack />}
            onClick={() => handleDelete(index)}
          />
        );
      },
      width: '3.2%'
    }
  ];

  const columnsInternalDocument: FetchColumnsType<any> = [
    ...columnsDefault,
    {
      title: t('required_documents:response_status'),
      dataIndex: 'isConfirm',
      className: 'remove-before',
      render(value: boolean, record: IDocumentList, index: number) {
        if (isNil(value)) return DataViewer.display(null);
        const isConfirm = record.isConfirm;
        const text = isConfirm ? t('required_documents:compatible') : t('required_documents:not_compatible');
        const userName = record?.nameConfirm;
        const nameConfirm = record?.statusConfirm ? userName : `(${t('account_list:status_account:inactive')}) ${userName}`;
        return (
          <div className='flex flex-col whitespace-pre-wrap'>
            <FormCheckbox name={`${type}.${index}.isConfirm`} handleChange={(value) => handleChangeStatus(value, record)}>
              {text}
            </FormCheckbox>
            {record.isShowSubmittedName && !isNil(record?.statusConfirm) && isConfirm && userName && (
              <p>
                {t('required_documents:counterpart')} : {nameConfirm}
              </p>
            )}
          </div>
        );
      },
      width: '18.5%'
    },
    {
      dataIndex: 'process',
      className: '!overflow-visible',
      render(_value: any, record: IDocumentList, index: number) {
        return (
          <Button
            className='!w-[30px] !h-[36px]'
            disabled={couldNotBeDelete(record)}
            type='text'
            icon={<TrashBlack />}
            onClick={() => handleDelete(index)}
          />
        );
      },
      width: '3.2%'
    }
  ];

  const submissionMOTOptions = [
    {
      label: t('required_documents:unnecessary'),
      value: SUBMISSION_MOT.unnecessary,
      id: SUBMISSION_MOT.unnecessary
    },
    {
      label: t('required_documents:need'),
      value: SUBMISSION_MOT.need,
      id: SUBMISSION_MOT.need
    }
  ];

  const handleDelete = (index: number) => {
    remove(index);
  };

  const addNewLine = () => {
    if (fieldDocumentList.length >= MAX_ITEM) return;
    const lastItem: any = fieldDocumentList[fieldDocumentList.length - 1] || {};
    const isConfirm = type === DOCUMENT_TYPE.projectRequiredDocuments ? null : false;
    append({
      ...REQUIRED_DOCUMENT_DEFAULT_VALUE.projectRequiredDocuments[0],
      id: uuidv4(),
      key: uuidv4(),
      displayOrder: fieldDocumentList.length ? lastItem.displayOrder + 1 : 1,
      isConfirm,
      IsCreate: true
    });
  };

  const handleChangeName = (value: string, op: any, index: number) => {
    const valueOption = documentNameOption.find((item) => item.value === value);
    let numberOfCopy,
      note,
      submissionToMot = null,
      documentId,
      documentName,
      managementCode = null;
    if (value) {
      numberOfCopy = valueOption?.numberOfCopy;
      note = valueOption?.note;
      submissionToMot = valueOption?.submissionToMot;
      documentId = value;
      documentName = valueOption?.name ?? op?.name;
      managementCode = valueOption?.label ?? op?.label;
    }

    const itemUpdate = fieldDocumentList[index];
    update(index, {
      ...itemUpdate,
      numberOfCopy,
      note,
      submissionToMot,
      documentId,
      documentName,
      managementCode,
      CountryId: projectCountryId
    });
  };

  const handleChangeStatus = (value: boolean, record: IDocumentList) => {
    if (value) {
      record.isShowSubmittedName = false;
      record.nameConfirm = user?.name;
      record.currentChecked = true; // check confirm by other
    }
  };

  const handleDeleteItems = (items: Key[]) => {
    const arrayIndex = items.map((key) => {
      return fieldDocumentList.findIndex((doc) => doc?.id === key);
    });
    remove(arrayIndex);
  };

  const columns = type === DOCUMENT_TYPE.projectRequiredDocuments ? columnsDocumentList : columnsInternalDocument;

  const isError = () => {
    return !!formMethod?.formState?.errors[type];
  };

  const watchFieldsDocumentList = formMethod.watch(type);
  const title =
    type === DOCUMENT_TYPE.projectRequiredDocuments ? t('required_documents:required_documents') : t('required_documents:internal_documents');

  const couldNotBeDelete = (record: IDocumentList) => {
    return !!(record?.documentTask?.statusName && record?.documentTask?.statusName !== STATUS_NAME.TODO);
  };

  return (
    <BaseCollapse ref={ref} id={type} title={title} isError={isError()}>
      {type === DOCUMENT_TYPE.projectRequiredDocuments && (
        <Row>
          <p className='flex items-center gap-1'>
            <Note /> {t('required_documents:note')}
          </p>
        </Row>
      )}
      <Row>
        <TableBase
          loading={loading}
          className='w-full table-edit'
          showAddLine={false}
          columns={columns}
          rowClassName={(record: IDocumentList) => (couldNotBeDelete(record) ? 'inactive' : '')}
          rowSelection={{
            getCheckboxProps: (record) => ({
              disabled: couldNotBeDelete(record)
            })
          }}
          scrollTable={{ x: 0, maxHeight: '100vh - 248px' }}
          showDelete={hasPermission(s.DELETE_PROJECT_LIST, s.V)}
          showDownload={false}
          pagination={false}
          deleteConfirmPopup={{
            type: TYPE.DISCARD,
            title: t('common:MSG_029.1_title', { item: t('required_documents:required_documents') }) ?? '',
            msg: `${t('common:MSG_029.1_description', { item: t('required_documents:required_documents') })} ${t('common:MSG_029_description2', {
              item: t('required_documents:required_documents')
            })}`
          }}
          locale={{
            emptyText: hasPermission(s.VIEW_DOCUMENT_LIST, s.V) && (
              <EmptyDataWithIcon
                icon={null}
                title={null}
                description={{
                  content: 'template_layout:emty_table'
                }}
                button={null}
              />
            )
          }}
          dataSource={watchFieldsDocumentList}
          handleDeleteClick={handleDeleteItems}
        />
      </Row>
      <Row>
        <div className='max-w-[140px] mt-3'>
          <BaseButton
            disabled={watchFieldsDocumentList?.length >= MAX_ITEM}
            icon={<PlusIcon />}
            type='secondary'
            block
            size='medium'
            onClick={addNewLine}
          >
            {t('required_documents:add_line')}
          </BaseButton>
        </div>
      </Row>
    </BaseCollapse>
  );
});

export default DocumentListComponent;
