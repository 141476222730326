import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditHistory from '@/pages/master-data/components/edit-history/EditHistory';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal, { IBaseModalType } from '@/components/base-modal/BaseModal';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { TASK_MAX_LENGTH, TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { Ii18n } from '@/utils/interfaces/i18n';

import { IPhraseCategory } from '../../phrase-list/components/PrimaryFormFilter';
import { SchemaCreate } from './FormValidation';

export interface IPhaseCreateEdit {
  id?: string;
  categoryId?: string;
  title?: string;
  content?: string;
  version?: number;
}

interface IPhrasePopupProps extends IBaseModalType {
  data: IPhaseCreateEdit;
  onSubmit?: () => void;
  onCancel?: () => void;
}

function PhrasePopup({ data, onSubmit, onCancel, openModal, ...restProps }: IPhrasePopupProps) {
  const { t }: Ii18n = useTranslation();
  const [categoryOption, setCategoryOption] = useState<DefaultOptionType[]>([]);

  const { data: phraseCategories, loading: loadingCategory, fetchData } = useFetch<IPhraseCategory[]>(`/prj/phrases/category`, 'GET');
  const isEdit = !!data?.id;
  const { loading, mutate: upsertPhrase } = useMutation(isEdit ? `/prj/phrases/${data.id}` : `/prj/phrases/`, {
    method: isEdit ? 'PUT' : 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', {
      item: t(isEdit ? 'button:keep' : 'button:create'),
      name: t('master_data:phrase:title_label') || ''
    }),
    paramMsg: {
      item: t('master_data:phrase:title_label'),
      field: t('master_data:phrase:title_label'),
      name: t('master_data:phrase:title_label')
    }
  });

  const form = useForm<any>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(SchemaCreate),
    defaultValues: { ...data }
  });

  useEffect(() => {
    form.reset({ ...data });
  }, [data]);

  useEffect(() => {
    setCategoryOption(
      (phraseCategories ?? []).map((i) => ({
        label: i.name,
        value: i.id
      }))
    );
  }, [phraseCategories]);

  useEffect(() => {
    if (openModal) {
      fetchData();
    }
  }, [openModal]);

  const { title } = form?.watch();

  const handleCloseCreateModal = useCallback(async () => {
    if (form.formState.isDirty) {
      const title = t('common:MSG_C_002:title');
      const msg = t('common:MSG_C_002:description');
      const showPopup = await showBasePopup({ title, msg, type: TYPE.DISCARD });
      if (showPopup !== 'confirm') {
        return;
      }
    }
    form.clearErrors();
    onCancel && onCancel();
  }, [form.formState.isDirty]);

  const handleActionPhrase: SubmitHandler<any> = async (fieldValues) => {
    const body = isEdit ? { ...fieldValues, id: data?.id, version: data?.version } : { ...fieldValues, id: undefined };
    const result = await upsertPhrase({ ...body });
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      onSubmit && onSubmit();
    }
  };

  const disableSaveBtn = () => {
    return (!form.formState.isDirty && isEdit) || form.formState.isSubmitting || loading;
  };

  return (
    <BaseModal
      openModal={openModal}
      maskClosable={false}
      onCancel={handleCloseCreateModal}
      destroyOnClose
      width={800}
      title={form.getValues('id') ? t('master_data:phrase:edit_label') : t('master_data:phrase:create_new_label')}
      className='phrase-master-modal'
      contentElement={
        <FormProvider {...form}>
          <Row gutter={16} className='mb-4'>
            <Col span={24} className='mb-6'>
              <FormSelect
                required
                name='categoryId'
                control={form.control}
                loading={loadingCategory}
                options={categoryOption}
                label={t('master_data:phrase:category_label')}
                placeholder={t('placeholder:select', { field: t('master_data:phrase:category_label') })}
                defaultValue={data?.categoryId ?? undefined}
                allowAddOnSearch
                addRequest={{
                  mappingParams(inputValue) {
                    return {
                      name: inputValue
                    };
                  },
                  method: 'POST',
                  apiEndPoint: `/prj/phrases/category`,
                  defaultParams: {}
                }}
                handleChange={() => {
                  if (!title) return;
                  form.trigger('title');
                }}
                onAddError={() => {
                  fetchData();
                }}
              />
            </Col>
            <Col span={24} className='mb-6'>
              <FormInput
                placeholder={`${t('common:MSG_001_textbox', { field: t('master_data:phrase:title_label') })}`}
                name='title'
                defaultValue={data?.title ?? undefined}
                labelTx={t('master_data:phrase:title_label')}
                required
              />
            </Col>
            <Col span={24}>
              <EditorWithCounter
                name='content'
                required
                limit={TASK_MAX_LENGTH}
                defaultValue={data?.content ?? undefined}
                label={t('master_data:phrase:content_label')}
                placeholder={`${t('common:MSG_001_textbox', { field: t('master_data:phrase:content_label') })}`}
                editorWrapperProps={{ className: '!h-[372px]' }}
              />
            </Col>
          </Row>
          {isEdit && <EditHistory data={form.getValues()} />}
          <div className='flex mt-[24px] justify-end space-x-4 phrase-btn-create-edit'>
            <div>
              <BaseButton
                disabled={form.formState.isSubmitting || loading}
                type='tertiary'
                size='medium'
                className='!py-[12px] mr-[10px]'
                onClick={handleCloseCreateModal}
              >
                {t('button:cancel')}
              </BaseButton>
            </div>
            <div>
              <BaseButton type='primary' size='medium' htmlType='submit' disabled={disableSaveBtn()} onClick={form.handleSubmit(handleActionPhrase)}>
                <span> {t('button:keep')} </span>
              </BaseButton>
            </div>
          </div>
        </FormProvider>
      }
      {...restProps}
    />
  );
}

export default PhrasePopup;
